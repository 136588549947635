import React from "react";
import {Button, ButtonRow, PageState, WarningIcon, Text, ErrorIcon, Card} from "@myob/myob-widgets";
import "./Common.css"
import notFound from "../assets/no-results-found.svg"
import somethingWrong from "../assets/something-went-wrong.svg"

function CustomTitle(text,type="error") {
  return (<div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left'
  }}>
    {type === "warning"
      ? <WarningIcon color="warning" style={{marginRight: 8}} />
      : <ErrorIcon color="danger" style={{marginRight: 8}}/>}
    {text}
  </div>);
}

function ErrorTitle(text) {
  return (<div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left'
  }}>
    <ErrorIcon color="danger" style={{marginRight: 8}} />{text}
  </div>);
}

function ErrorView({type, redirectUrl}) {

  const handleCancel = () => {
    window.location.href = redirectUrl;
  }

  const errorTypes = {
    notFound: {
      title: "Page Not Found",
      description: 'This page doesn\'t exist',
      img: notFound,
      alt: "page not found"
    },
    somethingWentWrong: {
      title: "Something went wrong",
      description: "If the issue persists, contact MYOB support",
      img: somethingWrong,
      alt: "something went wrong",
    },
    applicationCannotProceed: {
      title: "Application cannot proceed",
      description: "Application cannot be reverted to previous status. Please start a new application if you wish to connect a new bank feed.",
      img: somethingWrong,
      alt: "application cannot proceed",
    },
    applicationFailed: {
      title: "Application failed",
      description: "If the issue persists, contact MYOB support",
      img: somethingWrong,
      alt: "application failed",
    },
    applicationResultFailed: {
      title: "Page Not Found",
      description: 'This page doesn\'t exist',
      img: notFound,
      alt: "page not found",
    },
    finalisationFailed: {
      title: "Finalisation failed",
      description: "Accounts cannot be connected, please try again. If the issue persists, contact MYOB support",
      img: somethingWrong,
      alt: "finalisation failed",
    },
    openBankingOptOutError: {
      title: ErrorTitle("Accounts not connected"),
      description: (
        <div style={{paddingLeft: 8}}>
          <Text textAlign="center" style={{marginBottom: 16}}> Your accounts were not connected because you stopped the setup.
          We have not saved any account data</Text>
          {!redirectUrl && <Text textAlign="center">If you still want to connect your bank or credit cards, close this window and start the setup again.</Text>}
        </div>
      ),
    },
    openBankingUsrDhNotListedError: {
      title: CustomTitle("Unable to connect to this bank", "warning"),
      description: (
        <div style={{paddingLeft: 8}}>
          <Text textAlign="center" style={{marginBottom: 16}}>This bank does not support connections to MYOB.</Text>
          {!redirectUrl && <Text textAlign="center">To connect a different bank, close this window and start the setup again.</Text>}
        </div>
      ),
    },
    openBankingGenericRedirectError: {
      title: CustomTitle("Something went wrong", "error"),
      description: (
        !redirectUrl && <div style={{paddingLeft: 8}}>
          <Text textAlign="center">To try again, close this window and start the setup again.</Text>
        </div>
      ),
    },
    openBankingFinaliseError: {
      title: "Unable to load feeds",
      description: "Your accounts are connected, but we're unable to show your transactions right now. Please try again later.",
      img: somethingWrong,
      alt: "open banking finalise error",
    },
    noAccountsError: {
      title: CustomTitle("Accounts not connected", "error"),
      description: (
        <div style={{paddingLeft: 8}}>
          <Text textAlign="center" style={{marginBottom: 16}}> No accounts were selected in the setup.
            We have not saved any account data.</Text>
          {!redirectUrl && <Text textAlign="center">If you still want to connect your bank or credit cards, close this window and start the setup again.</Text>}
        </div>
      ),
    },
  }

  return (
    <div className={'page-block'}>
        <Card>
          <PageState
            className={'img-block'}
            title={errorTypes[type].title}
            image={<img src={errorTypes[type].img}
            alt={errorTypes[type].alt} />}
            description={
              <React.Fragment>
                {errorTypes[type].description}
              </React.Fragment>
            }
          >
          </PageState>
      </Card>
      { redirectUrl && (
        <ButtonRow className="animate">
        <Button
          className="redirect-from-error-page"
          style={{marginBottom:'20px'}}
          onClick={handleCancel}
        >
          Go back to Manage Bankfeeds
        </Button>
      </ButtonRow> )}
    </div>
  );
}

export default ErrorView;
