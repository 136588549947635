import React, {useEffect, useState} from "react";
import ErrorView from "../../common/ErrorView";
import Loading from "../../common/Loading";
import FeedMigrationView from "./migration-components/FeedMigrationView";
import FeedMigrationConfirmationView from "./migration-components/FeedMigrationConfirmationView";
import {api} from "../../Dependencies";

function MigrateFinaliseContainer({authCode, consentId, migrationApplicationData, error}) {
  const [isLoading, setLoading] = useState(true);
  const [accountData, setAccountData] = useState({});
  const [migratedResult, setMigratedResult] = useState({});
  const [selectedBankAccounts, setSelectedBankAccounts] = useState({});
  const [selectedCreditCards, setSelectedCreditCards] = useState({});
  const [isFeedMigrated, setFeedMigrated] = useState(false)
  const [errorType, setErrorType] = useState(error ? 'applicationFailed' : null);

  useEffect(() => {
    (async () => {
      try {
        const data = await api.fetchAccountDataForMigration(authCode, migrationApplicationData, consentId);
        setAccountData(data);
        setLoading(false);
      } catch (err) {
        if (err.message !== 'Unauthorized') {
          err.status === 409 ? setErrorType('applicationCannotProceed') : setErrorType('applicationFailed');
        }
      }
    })();
  }, []);

  const atobDecoding = (data) => {
    return Buffer.from(data, "base64").toString();
  }

  const getDecodedValue = (key) => {
    const urlParams = new URLSearchParams(atobDecoding(migrationApplicationData));
    return urlParams.get(key);
  }

  const handleRedirect = () => {
    window.location.href = accountData.productRedirectUrl;
  }

  const setResultAndSelections = ({migratedResult, bankAccountSelections, creditCardSelections}) => {
    setMigratedResult(migratedResult)
    setSelectedBankAccounts(bankAccountSelections)
    setSelectedCreditCards(creditCardSelections)
  }

  const isFromBNZ = () => getDecodedValue("source")?.toLowerCase() === "ProprietaryAPI".toLowerCase();

  const renderFeedMigrationView = () => <FeedMigrationView accountData={accountData} isLoading={isLoading} setLoading={setLoading} setFeedMigrated={setFeedMigrated} isFromBNZ={isFromBNZ()} setErrorType={setErrorType} handleRedirect={handleRedirect} setResultAndSelections={setResultAndSelections}/>

  const renderFeedMigrationConfirmationView = () => <FeedMigrationConfirmationView accountData={accountData} selectedBankAccounts={selectedBankAccounts} selectedCreditCards={selectedCreditCards} migratedResult={migratedResult} setMigratedResult={setMigratedResult} setErrorType={setErrorType} handleRedirect={handleRedirect} />

  return (
    <div>
      {
        errorType ? <ErrorView type={errorType} redirectUrl={getDecodedValue("productRedirectUrl")}/> :
          isLoading ?
            <Loading message={"Fetching your feed info..."}/> :
            !isFeedMigrated ? renderFeedMigrationView() : renderFeedMigrationConfirmationView()
      }
    </div>
  )
}
export default MigrateFinaliseContainer;
