import {Connect} from "connect-web-sdk";
import * as PropTypes from "prop-types";

export default function ConnectPage({connectedUrl, connectEventHandlers }) {
  const atobDecoding = (data) => {
    return Buffer.from(data, "base64").toString();
  }
  const decodedConnectedUrl = atobDecoding(connectedUrl)

  const connectOptions = {
    overlay: '#F9F9F9',
  };

  Connect.launch( decodedConnectedUrl, connectEventHandlers, connectOptions );
  return (<></>)
}

ConnectPage.propTypes = {
  connectedUrl: PropTypes.string,
  connectEventHandlers: PropTypes.object
};
