import * as PropTypes from "prop-types";
import { Connect} from 'connect-web-sdk';

export default function OnboardOpenBankingInitiate({applicationId, connectedUrl, productRedirectUrl}) {
  const atobDecoding = (data) => {
    return Buffer.from(data, "base64").toString();
  }

  const decodedConnectedUrl = atobDecoding(connectedUrl)

  const connectEventHandlers = {
    onDone: () => {
      const state=window.btoa(`source=OpenBanking&applicationId=${applicationId}&productRedirectUrl=${productRedirectUrl}`).toString()
      window.location.href =`/onboard/finalise?state=${state}`;
      Connect.destroy();
    },
    onCancel: () => { },
    onError: () => {},
    onRoute: () => {},
    onUser: () => { },
    onLoad: () => {}
  };
  const connectOptions = {
    overlay: '#F9F9F9',
  };

  Connect.launch( decodedConnectedUrl, connectEventHandlers, connectOptions );
  return (<></>)
}

OnboardOpenBankingInitiate.propTypes = {
  applicationId: PropTypes.string,
  connectedUrl: PropTypes.string,
  productRedirectUrl: PropTypes.string
};







