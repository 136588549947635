import * as _ from "lodash";
import {api} from "../../../Dependencies";
import AlertSection from "./AlertSection";
import {Button, ButtonRow, PageHead} from "@myob/myob-widgets";
import MatchedResultCard from "./MatchedResultCard";
import NotMatchedResultCard from "./NotMatchedResultCard";
import React, {useState} from "react";

function FeedMigrationConfirmationView({accountData, selectedBankAccounts, selectedCreditCards, migratedResult, setMigratedResult, setErrorType, handleRedirect}) {
  const [isRetryLoading, setRetryLoading] = useState(false);
  const bankAccounts = accountData?.bankFeedsForMigration?.bankAccounts || [];
  const creditCards = accountData?.bankFeedsForMigration?.creditCards || [];

  const filterMatchedItems = (accounts, results) =>
    _.filter(accounts, account => _.has(results, account.id));

  const filterUnmatchedItems = (accounts, results) =>
    _.filter(accounts, account => !_.has(results, account.id));

  const allSuccess = _.chain(migratedResult).values().every(value => !!value).value();
  const failureCount = _.chain(migratedResult).values().filter(value => !value).size().value();
  const unmatchedBankAccounts = filterUnmatchedItems(bankAccounts, migratedResult);
  const unmatchedCreditCards = filterUnmatchedItems(creditCards, migratedResult);

  const isMatched = (row) => {
    return _.has(migratedResult, row.id) && migratedResult[row.id];
  };

  const buildTableData = (data, selectedItems) =>
    _.map(data, (item) => {
      const selectItem = selectedItems[item.id];
      return {...item, ...{name: selectItem?.name, number: selectItem?.number, matched: isMatched(item)}}
    })

  const handleRetryClick = async () => {
    try {
      const failedAccounts = _.chain(migratedResult)
        .pickBy(value => !value)
        .keys()
        .map(key => {
          const account = selectedBankAccounts[key] || selectedCreditCards[key];
          return {feedId: key, account};
        })
        .value();

      if (!_.isEmpty(failedAccounts) && !isRetryLoading) {
        setRetryLoading(true);
        try {
          const retryResult = await api.migrateFeeds(accountData.applicationId, failedAccounts);
          setMigratedResult(prev => ({...prev, ...retryResult}));
          setRetryLoading(false);
        } catch (err) {
          setErrorType('applicationFailed');
          setRetryLoading(false);
        }
      }
    } catch (err) {
      setErrorType('applicationFailed');
    }
  };

  return (
    <div className={'page-block--sm animate'} style={{padding: '2.4rem', minWidth: '1168px'}}>
      {AlertSection(
        isRetryLoading,
        _.size(migratedResult),
        _.size(unmatchedBankAccounts),
        _.size(unmatchedCreditCards),
        allSuccess,
        failureCount
      )}
      <PageHead title="Reconnect bank accounts"/>
      <>
        <MatchedResultCard
          bankAccountTableData={buildTableData(filterMatchedItems(bankAccounts, migratedResult), selectedBankAccounts)}
          creditCardTableData={buildTableData(filterMatchedItems(creditCards, migratedResult), selectedCreditCards)}
          handleRetryClick={handleRetryClick}
          migratedResult={migratedResult}/>
        <NotMatchedResultCard
          bankAccountTableData={buildTableData(unmatchedBankAccounts, selectedBankAccounts)}
          creditCardsTableData={buildTableData(unmatchedCreditCards, selectedCreditCards)}/>
      </>
      <ButtonRow className="animate">
        <Button
          className="finish-migration"
          style={{marginBottom: '20px'}}
          onClick={handleRedirect}
          type="primary"
        >
          Finish
        </Button>
      </ButtonRow>
    </div>
  )
}
export default FeedMigrationConfirmationView;
