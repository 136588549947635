import React, {useContext, useEffect, useState} from "react";
import Loading from "../../common/Loading";
import ErrorView from "../../common/ErrorView";
import {apiContext} from "../../ApiContext";
import SelectAccountsContainer from "./select-accounts/SelectAccountsContainer";
import FeedConfirmationResultContainer from "./feed-confirmation/openbanking/FeedConfirmationResultContainer";
import {FullStory} from "@fullstory/browser";
import * as _ from 'lodash';

function OnboardFinaliseContainer({ authCode, consentId, onboardingApplicationData, error }) {

  const [isLoading, setLoading] = useState(true)
  const [errorType, setErrorType] = useState(error ? 'applicationFailed' : null)
  const [feedAccountDetails, setFeedAccountDetails] = useState()
  const [accounts, setAccounts] = useState([])
  const [accountsToOnboard, setAccountsToOnboard] = useState([])
  const [isFeedCreated, setFeedCreated] = useState(false)
  const [isBankFeedLimitExceeded, setBankFeedLimitExceeded] = useState(true)
  const api = useContext(apiContext)

  const areAllAccountsOnboarded = () => {
    return !_.isEmpty(accounts) && _.every(accounts, account => account.onboarded);
  }
  const accountsAlreadyOnboarded = (accounts) => {
    return _.filter(accounts, account => account.onboarded);
  }

  const fetchAccountsNotOnboarded = (accounts) => {
    return _.filter(accounts, account => !account.onboarded);
  }

  const isFromOpenBanking = () => {
    return getDecodedValue("source")?.toLowerCase() === "OpenBanking".toLowerCase();
  }

  const revokeConsentForOpenBanking = async () => {
    try {
      await api.revokeConsent(getDecodedValue("applicationId"), consentId);
    } catch {
      setErrorType('openBankingFinaliseError');
    }
  }

  const handleOpenBankingError = async () => {
    setErrorType('openBankingFinaliseError');
    await revokeConsentForOpenBanking();
  }

  const createFeeds = async (selectedAccounts) => {
    setLoading(true);
    setAccountsToOnboard(selectedAccounts);

    try {
      const applicationId = getDecodedValue("applicationId");
      await api.createFeeds(applicationId, selectedAccounts);
      setFeedCreated(true);
    } catch (err) {
      if (isFromOpenBanking()) {
        await handleOpenBankingError();
      } else {
        err.status === 409 ? setErrorType('applicationCannotProceed') : setErrorType('finalisationFailed');
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const getFeedAccountDetails = async () => {
      let accountData;
      try {
        accountData = await api.fetchAccountData(authCode, onboardingApplicationData, consentId);
        setFeedAccountDetails(accountData);
        setAccounts(accountData.accounts);
      } catch (err) {
        if (err.message !== 'Unauthorized') {
          if (isFromOpenBanking()) {
            await handleOpenBankingError();
          } else {
            err.status === 409 ? setErrorType('applicationCannotProceed') : setErrorType('applicationFailed');
          }
          return;
        }
      }

      if (_.isEmpty(accountData.accounts)) {
        setErrorType('noAccountsError');
        return;
      }

      if (isFromOpenBanking()) {
        const accountsNotOnboarded = fetchAccountsNotOnboarded(accountData.accounts);
        if (accountData.availableFeeds == null || accountsNotOnboarded.length <= accountData.availableFeeds) {
          setBankFeedLimitExceeded(false);
          _.isEmpty(accountsNotOnboarded) ? await revokeConsentForOpenBanking() : await createFeeds(accountsNotOnboarded);
        }
      }
      setLoading(false)
    };

    getFeedAccountDetails();
  }, []);

  const atobDecoding = (data) =>{
    return Buffer.from(data, "base64").toString();
  }

  const getDecodedValue = (key) => {
    const decodedAppData = atobDecoding(onboardingApplicationData).split('&');
    const dataPairs = decodedAppData.map(dataPair => dataPair.split('='));
    let pair = dataPairs.find(dataPair => dataPair[0] === key);
    return pair && pair[1];
  }

  const redirectUrl = getDecodedValue("productRedirectUrl")

  const renderError = () => {
    FullStory('trackEvent', {
      name: 'Onboard Finalise Error',
      properties: {
        error_code: errorType,
        source: getDecodedValue("source")
      },
    })
    return <ErrorView type={errorType} redirectUrl={redirectUrl}/>
  }

  const prepareAccountsForOnboarding = (accounts) => {
    return isFromOpenBanking() ? _.sortBy(accounts, a => a.onboarded) : fetchAccountsNotOnboarded(accounts);
  }

  const renderFeedsOnboardingView = () => (isFeedCreated || areAllAccountsOnboarded()) ?
    (
      <FeedConfirmationResultContainer
        source={getDecodedValue("source")}
        areAllAccountsOnboarded={areAllAccountsOnboarded()}
        redirectUrl={redirectUrl}
        accountsNotYetOnboarded={fetchAccountsNotOnboarded(accounts)}
        selectedOnboardAccounts={accountsToOnboard}
        alreadyOnboardedAccounts={accountsAlreadyOnboarded(accounts)}
      />
    ) :
    (
      isBankFeedLimitExceeded && <SelectAccountsContainer
        accounts = {prepareAccountsForOnboarding(accounts)}
        bankfeedLimit = {feedAccountDetails.bankfeedLimit}
        availableFeeds = {feedAccountDetails.availableFeeds}
        productRedirectUrl={redirectUrl}
        subscriptionUpgradeUrl={feedAccountDetails.subscriptionUpgradeUrl}
        onSubmit={createFeeds}
        source={getDecodedValue("source")}
      />
    )

  return (
    <div>
      {
        errorType ? renderError() :
          isLoading ?
            <Loading message={"Fetching your feed info..."}/> :
            renderFeedsOnboardingView()
      }
    </div>
  )
}

export default OnboardFinaliseContainer;
